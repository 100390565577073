body {
  background-color: black;
  overflow-y: hidden;
}

.intro {
  color: #ccc;
}

.mask {
  color: #fff;
  font-size: 1.5em;
}

.App {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
}

.App header {
  background-color: black;
  border-bottom: 1px solid #181818;
  height: 10vh;
  min-height: 50px;
  color: #fff;
  position: fixed;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.brand-dim {
  color: black;
  background-color: #f79a24;
  margin-left: 5px;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: black;
}

main {
  padding-left: 10px;
  padding-right: 10px;
  height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: black;
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

form button {
  width: 20%;
  background-color: rgba(56, 56, 143, 0.402);
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(14, 14, 14);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

ul,
li {
  text-align: left;
  list-style: none;
}

.unsupported-message {
  color: #ccc;
  padding: 10px;
}

.text {
  max-width: 500px;
  padding: 10px 20px;
  position: relative;
  color: white;
  text-align: justify;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sent {
  flex-direction: row-reverse;
  margin-bottom: .2em;
}

.sent:last-of-type {
  margin-bottom: 1em;
}

.sent .text {
  max-width: 70%;
  word-wrap: break-word;
  padding: 10px;
  border-radius: 1rem 0.3rem 0.3rem 1rem;

  background: linear-gradient(
      180deg,
      #855414 0%,
      #925c16 50%,
      #F79A24 100%
    )
    no-repeat center;
  background-attachment: fixed;
  color: #fff;
}
.sender > .message.sent:last-child > .text:last-child {
  border-radius: 1rem 0.3rem 1rem 1rem;
}

.sender > .message.sent:first-child > .text:first-child {
  border-radius: 1rem 1rem 0rem 1rem;
}

.sender > .message.sent:only-child > .text:only-child {
  border-radius: 1rem 1rem 1rem 1rem;
}

.received .text {
  background: #262626;
  color: #fff;
  max-width: 70%;
  word-wrap: break-word;
  padding: 10px;
  border-radius: 1rem;
}

.sign-out {
  color: red;
  border-radius: 0;
  background: black;
}
.sign-out-button {
  transition: 0.3s;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.sign-out-button:hover {
  transition: 0.3s;
  background: #3a3a3a;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.justify {
  text-align: justify;
}

/* Login button */
.login-with-google-btn {
  width: 200px;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: #212121;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  background-color: #3a3a3a;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #3a3a3a;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}